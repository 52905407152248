const Colleges = [
  "A.T. Still University",
  "Abilene Christian University",
  "Abraham Baldwin Agricultural College",
  "Academy of Art University",
  "Adams State University",
  "Adelphi University",
  "Adler Graduate School",
  "Adler University",
  "Adrian College",
  "AdventHealth University",
  "Agnes Scott College",
  "Air Force Institute of Technology",
  "Alabama A&M University",
  "Alabama State University",
  "Alaska Bible College",
  "Alaska Pacific University",
  "Albany College of Pharmacy and Health Sciences",
  "Albany Law School",
  "Albany Medical College",
  "Albany State University",
  "Albertus Magnus College",
  "Albion College",
  "Albright College",
  "Alcorn State University",
  "Alderson Broaddus University",
  "Alfred State College",
  "Alfred University",
  "Alice Lloyd College",
  "Allegheny College",
  "Allen College",
  "Allen University",
  "Alliant International University",
  "Alma College",
  "Alvernia University",
  "Alverno College",
  "Amberton University",
  "American Baptist College",
  "American Film Institute Conservatory",
  "American International College",
  "American Jewish University",
  "American University",
  "Amherst College",
  "Anderson University",
  "Anderson University, Indiana",
  "Andrews University",
  "Angelo State University",
  "Anna Maria College",
  "Antioch University",
  "Antioch University Los Angeles",
  "Antioch University New England",
  "Antioch University Santa Barbara",
  "Antioch University Seattle",
  "Appalachian Bible College",
  "Appalachian College of Pharmacy",
  "Appalachian State University",
  "Aquinas College",
  "Aquinas College, Tennessee",
  "Arcadia University",
  "Arizona Christian University",
  "Arizona State University",
  "Arkansas Baptist College",
  "Arkansas State University",
  "Arkansas Tech University",
  "Arlington Baptist University",
  "Art Academy of Cincinnati",
  "ArtCenter College of Design",
  "Asbury University",
  "Ashland University",
  "Assumption College",
  "Athens State University",
  "Atlanta Metropolitan State College",
  "Auburn University",
  "Auburn University at Montgomery",
  "Augsburg College",
  "Augusta University",
  "Augustana College",
  "Augustana University",
  "Aurora University",
  "Austin College",
  "Austin Peay State University",
  "Ave Maria University",
  "Averett University",
  "Avila University",
  "Azusa Pacific University",
  "Babson College",
  "Bacone College",
  "Baker College",
  "Baker University",
  "Baldwin Wallace University",
  "Ball State University",
  "Bank Street College of Education",
  "Baptist Bible College",
  "Baptist Memorial College of Health Sciences",
  "Baptist University of the Americas",
  "Barclay College",
  "Bard College",
  "Bard College at Simon's Rock",
  "Barnard College",
  "Barnes-Jewish College Goldfarb School of Nursing",
  "Barry University",
  "Barton College",
  "Baruch College, CUNY",
  "Bastyr University",
  "Bates College",
  "Bay Path University",
  "Bay State College",
  "Baylor College of Medicine",
  "Baylor University",
  "Beacon College",
  "Becker College",
  "Belhaven University",
  "Bellarmine University",
  "Bellevue College",
  "Bellevue University",
  "Bellin College",
  "Belmont Abbey College",
  "Belmont University",
  "Beloit College",
  "Bemidji State University",
  "Benedict College",
  "Benedictine College",
  "Benedictine University",
  "Benjamin Franklin Institute of Technology",
  "Bennett College",
  "Bennington College",
  "Bentley University",
  "Berea College",
  "Berkeley College",
  "Berklee College of Music",
  "Berry College",
  "Bethany College",
  "Bethany College, Kansas",
  "Bethany Lutheran College",
  "Bethel College",
  "Bethel University",
  "Bethesda University",
  "Bethune-Cookman University",
  "Beulah Heights University",
  "Binghamton University, State University of New York",
  "Biola University",
  "Birmingham-Southern College",
  "Bismarck State College",
  "Black Hills State University",
  "Blackburn College",
  "Blessing-Rieman College of Nursing",
  "Bloomfield College",
  "Bloomsburg University of Pennsylvania",
  "Blue Mountain College",
  "Bluefield College",
  "Bluefield State College",
  "Bluffton University",
  "Bob Jones University",
  "Boise Bible College",
  "Boise State University",
  "Boricua College",
  "Boston Architectural College",
  "Boston College",
  "Boston Graduate School of Psychoanalysis",
  "Boston University",
  "Bowdoin College",
  "Bowie State University",
  "Bowling Green State University",
  "Bradley University",
  "Brandeis University",
  "Brandman University",
  "Brazosport College",
  "Brenau University",
  "Brescia University",
  "Brevard College",
  "Brewton-Parker College",
  "Briar Cliff University",
  "Bridgewater College",
  "Bridgewater State University",
  "Brigham Young University",
  "Brigham Young University-Hawaii",
  "Brigham Young University-Idaho",
  "Brooklyn College",
  "Brooklyn Law School",
  "Broward College",
  "Brown Mackie College",
  "Brown University",
  "Bryan College",
  "Bryan College of Health Sciences",
  "Bryant and Stratton College",
  "Bryant University",
  "Bryn Athyn College",
  "Bryn Mawr College",
  "Bucknell University",
  "Buena Vista University",
  "Buffalo State College",
  "Butler University",
  "Cabarrus College of Health Sciences",
  "Cabrini University",
  "Cairn University",
  "Caldwell University",
  "California Baptist University",
  "California College of the Arts",
  "California Institute of Integral Studies",
  "California Institute of Technology",
  "California Institute of the Arts",
  "California Lutheran University",
  "California Polytechnic State University, San Luis Obispo",
  "California State Polytechnic University, Pomona",
  "California State University Channel Islands",
  "California State University Maritime Academy",
  "California State University San Marcos",
  "California State University, Bakersfield",
  "California State University, Chico",
  "California State University, Dominguez Hills",
  "California State University, East Bay",
  "California State University, Fresno",
  "California State University, Fullerton",
  "California State University, Long Beach",
  "California State University, Los Angeles",
  "California State University, Monterey Bay",
  "California State University, Northridge",
  "California State University, Sacramento",
  "California State University, San Bernardino",
  "California State University, Stanislaus",
  "California University of Pennsylvania",
  "California Western School of Law",
  "Calumet College of St. Joseph",
  "Calvary University",
  "Calvin College",
  "Cambridge College",
  "Cameron University",
  "Campbell University",
  "Campbellsville University",
  "Canisius College",
  "Capital University",
  "Capitol Technology University",
  "Cardinal Stritch University",
  "Carleton College",
  "Carlow University",
  "Carnegie Mellon University",
  "Carroll College",
  "Carroll University",
  "Carson-Newman University",
  "Carthage College",
  "Case Western Reserve University",
  "Castleton University",
  "Catawba College",
  "Cazenovia College",
  "Cedar Crest College",
  "Cedarville University",
  "Centenary College of Louisiana",
  "Centenary University",
  "Central Baptist College",
  "Central Christian College of Kansas",
  "Central Christian College of the Bible",
  "Central College",
  "Central Connecticut State University",
  "Central Methodist University",
  "Central Michigan University",
  "Central Penn College",
  "Central State University",
  "Central Washington University",
  "Centralia College",
  "Centre College",
  "Chadron State College",
  "Chamberlain University",
  "Chaminade University of Honolulu",
  "Champlain College",
  "Chapman University",
  "Charles R. Drew University of Medicine and Science",
  "Charleston Southern University",
  "Chatham University",
  "Chestnut Hill College",
  "Cheyney University of Pennsylvania",
  "Chicago State University",
  "Chipola College",
  "Chowan University",
  "Christian Brothers University",
  "Christopher Newport University",
  "Cincinnati Christian University",
  "City University of Seattle",
  "Claflin University",
  "Claremont Graduate University",
  "Claremont McKenna College",
  "Clarion University of Pennsylvania",
  "Clark Atlanta University",
  "Clark University",
  "Clarke University",
  "Clarks Summit University",
  "Clarkson College",
  "Clarkson University",
  "Clayton State University",
  "Clear Creek Baptist Bible College",
  "Cleary University",
  "Clemson University",
  "Cleveland Institute of Art",
  "Cleveland Institute of Music",
  "Cleveland State University",
  "Cleveland University-Kansas City",
  "Coastal Carolina University",
  "Coe College",
  "Cogswell Polytechnical College",
  "Coker University",
  "Colby College",
  "Colby-Sawyer College",
  "Colgate University",
  "College for Creative Studies",
  "College of Biblical Studies",
  "College of Central Florida",
  "College of Charleston",
  "College of Coastal Georgia",
  "College of Mount Saint Vincent",
  "College of Our Lady of the Elms",
  "College of Saint Benedict/Saint John's University",
  "College of Saint Elizabeth",
  "College of Saint Mary",
  "College of Southern Nevada",
  "College of St. Joseph",
  "College of Staten Island",
  "College of the Atlantic",
  "College of the Holy Cross",
  "College of the Ozarks",
  "College of William & Mary",
  "Colorado Christian University",
  "Colorado College",
  "Colorado Mesa University",
  "Colorado School of Mines",
  "Colorado State University",
  "Colorado State University-Pueblo",
  "Colorado Technical University",
  "Columbia Basin College",
  "Columbia College",
  "Columbia College Chicago",
  "Columbia College Hollywood",
  "Columbia College of Nursing",
  "Columbia College, South Carolina",
  "Columbia International University",
  "Columbia University in the City of New York",
  "Columbus College of Art and Design",
  "Columbus State University",
  "Concord University",
  "Concordia College",
  "Concordia College Alabama",
  "Concordia College-New York",
  "Concordia University Ann Arbor",
  "Concordia University Chicago",
  "Concordia University Irvine",
  "Concordia University Texas",
  "Concordia University Wisconsin",
  "Concordia University, Nebraska",
  "Concordia University, Oregon",
  "Concordia University, St. Paul",
  "Connecticut College",
  "Converse College",
  "Conway School of Landscape Design",
  "Coppin State University",
  "Corban University",
  "Cornell College",
  "Cornell University",
  "Cornerstone University",
  "Cornish College of the Arts",
  "Cottey College",
  "Covenant College",
  "Cox College",
  "Cranbrook Academy of Art",
  "Creighton University",
  "Criswell College",
  "Crossroads College",
  "Crowley's Ridge College",
  "Crown College",
  "Culver-Stockton College",
  "Cumberland University",
  "CUNY School of Law",
  "Curry College",
  "Curtis Institute of Music",
  "Daemen College",
  "Dakota State University",
  "Dakota Wesleyan University",
  "Dallas Baptist University",
  "Dallas Christian College",
  "Dalton State College",
  "Dartmouth College",
  "Davenport University",
  "Davidson College",
  "Davis & Elkins College",
  "Davis College",
  "Daytona State College",
  "Dean College",
  "Defiance College",
  "Delaware State University",
  "Delaware Valley University",
  "Delta State University",
  "Denison University",
  "Denver College of Nursing",
  "DePaul University",
  "DePauw University",
  "Des Moines University",
  "DeSales University",
  "DeVry University",
  "Dharma Realm Buddhist University",
  "Dickinson College",
  "Dickinson State University",
  "Dillard University",
  "Dixie State University",
  "Doane University",
  "Dominican College",
  "Dominican School of Philosophy & Theology",
  "Dominican University",
  "Dominican University of California",
  "Donnelly College",
  "Dordt University",
  "Drake University",
  "Drew University",
  "Drexel University",
  "Drury University",
  "Duke University",
  "Dunwoody College of Technology",
  "Duquesne University",
  "D'Youville College",
  "Earlham College",
  "East Carolina University",
  "East Central University",
  "East Georgia State College",
  "East Stroudsburg University",
  "East Tennessee State University",
  "East Texas Baptist University",
  "Eastern Connecticut State University",
  "Eastern Illinois University",
  "Eastern Kentucky University",
  "Eastern Mennonite University",
  "Eastern Michigan University",
  "Eastern Nazarene College",
  "Eastern New Mexico University",
  "Eastern Oregon University",
  "Eastern University",
  "Eastern Virginia Medical School",
  "Eastern Washington University",
  "East-West University",
  "Ecclesia College",
  "Eckerd College",
  "ECPI University",
  "Edgewood College",
  "Edinboro University of Pennsylvania",
  "Edward Waters College",
  "Elizabeth City State University",
  "Elizabethtown College",
  "Elmhurst College",
  "Elmira College",
  "Elon University",
  "Embry-Riddle Aeronautical University",
  "Emerson College",
  "Emmanuel College",
  "Emmanuel College, Georgia",
  "Emmaus Bible College",
  "Emory & Henry College",
  "Emory University",
  "Emporia State University",
  "Endicott College",
  "Erskine College",
  "Eureka College",
  "Evangel University",
  "Everglades University",
  "Fairfield University",
  "Fairleigh Dickinson University",
  "Fairmont State University",
  "Faith Baptist Bible College and Theological Seminary",
  "Farmingdale State College",
  "Fashion Institute of Technology",
  "Faulkner University",
  "Fayetteville State University",
  "Felician University",
  "Ferris State University",
  "Ferrum College",
  "Fielding Graduate University",
  "Finlandia University",
  "Fisher College",
  "Fisk University",
  "Fitchburg State University",
  "Five Towns College",
  "Flagler College",
  "Florida Agricultural and Mechanical University",
  "Florida Atlantic University",
  "Florida College",
  "Florida Gateway College",
  "Florida Gulf Coast University",
  "Florida Institute of Technology",
  "Florida International University",
  "Florida Memorial University",
  "Florida National University",
  "Florida Southern College",
  "Florida SouthWestern State College",
  "Florida State College at Jacksonville",
  "Florida State University",
  "Fontbonne University",
  "Fordham University",
  "Fort Hays State University",
  "Fort Lewis College",
  "Fort Valley State University",
  "Framingham State University",
  "Francis Marion University",
  "Franciscan Missionaries of Our Lady University",
  "Franciscan University of Steubenville",
  "Franklin and Marshall College",
  "Franklin College",
  "Franklin Pierce University",
  "Franklin University",
  "Franklin W. Olin College of Engineering",
  "Freed-Hardeman University",
  "Fresno Pacific University",
  "Friends University",
  "Frostburg State University",
  "Furman University",
  "Gallaudet University",
  "Gannon University",
  "Gardner-Webb University",
  "Geisinger Commonwealth School of Medicine",
  "Geneva College",
  "George Fox University",
  "George Mason University",
  "George Washington University",
  "Georgetown College",
  "Georgetown University",
  "Georgia College and State University",
  "Georgia Gwinnett College",
  "Georgia Institute of Technology",
  "Georgia Southern University",
  "Georgia Southwestern State University",
  "Georgia State University",
  "Georgian Court University",
  "Gettysburg College",
  "Glenville State College",
  "Goddard College",
  "God's Bible School and College",
  "Golden Gate University",
  "Goldey-Beacom College",
  "Gonzaga University",
  "Good Samaritan College of Nursing and Health Science",
  "Goodwin College",
  "Gordon College",
  "Gordon State College",
  "Goshen College",
  "Goucher College",
  "Governors State University",
  "Grace Christian University",
  "Grace College",
  "Graceland University",
  "Grambling State University",
  "Grand Canyon University",
  "Grand Valley State University",
  "Grand View University",
  "Granite State College",
  "Gratz College",
  "Great Basin College",
  "Great Lakes Christian College",
  "Greensboro College",
  "Greenville University",
  "Grinnell College",
  "Grove City College",
  "Guilford College",
  "Gulf Coast State College",
  "Gustavus Adolphus College",
  "Gwynedd Mercy University",
  "Hamilton College",
  "Hamline University",
  "Hampden-Sydney College",
  "Hampshire College",
  "Hampton University",
  "Hannibal-LaGrange University",
  "Hanover College",
  "Harding University",
  "Hardin-Simmons University",
  "Harrisburg University of Science and Technology",
  "Harris-Stowe State University",
  "Hartwick College",
  "Harvard University",
  "Harvey Mudd College",
  "Hastings College",
  "Haverford College",
  "Hawaii Pacific University",
  "Hebrew College",
  "Heidelberg University",
  "Helene Fuld College of Nursing",
  "Henderson State University",
  "Hendrix College",
  "Heritage Christian University",
  "Heritage University",
  "Herzing University",
  "High Point University",
  "Hilbert College",
  "Hillsdale College",
  "Hiram College",
  "Hobart and William Smith Colleges",
  "Hodges University",
  "Hofstra University",
  "Hollins University",
  "Holy Apostles College and Seminary",
  "Holy Cross College",
  "Holy Family College",
  "Holy Family University",
  "Holy Names University",
  "Hood College",
  "Hope College",
  "Hope International University",
  "Houghton College",
  "Houston Baptist University",
  "Howard Payne University",
  "Howard University",
  "Hult International Business School",
  "Humboldt State University",
  "Humphreys University",
  "Hunter College, CUNY",
  "Huntingdon College",
  "Huntington University",
  "Husson University",
  "Huston-Tillotson University",
  "Icahn School of Medicine at Mount Sinai",
  "Idaho State University",
  "Illinois College",
  "Illinois College of Optometry",
  "Illinois Institute of Technology",
  "Illinois State University",
  "Illinois Wesleyan University",
  "Immaculata University",
  "Indian River State College",
  "Indiana Institute of Technology",
  "Indiana State University",
  "Indiana University - Purdue University Indianapolis",
  "Indiana University Bloomington",
  "Indiana University East",
  "Indiana University Fort Wayne",
  "Indiana University Kokomo",
  "Indiana University Northwest",
  "Indiana University of Pennsylvania",
  "Indiana University South Bend",
  "Indiana University Southeast",
  "Indiana Wesleyan University",
  "Iona College",
  "Iowa State University of Science and Technology",
  "Iowa Wesleyan University",
  "Irell and Manella Graduate School of Biological Sciences",
  "Ithaca College",
  "Jackson State University",
  "Jacksonville State University",
  "Jacksonville University",
  "James Madison University",
  "Jamestown Business College",
  "Jarvis Christian College",
  "John Brown University",
  "John Carroll University",
  "John F. Kennedy University",
  "John Jay College of Criminal Justice",
  "John Paul the Great Catholic University",
  "Johns Hopkins University",
  "Johnson and Wales University",
  "Johnson C. Smith University",
  "Johnson University",
  "Judson College",
  "Judson University",
  "Juniata College",
  "Kalamazoo College",
  "Kansas City Art Institute",
  "Kansas City University of Medicine and Biosciences",
  "Kansas State University",
  "Kansas Wesleyan University",
  "Kean University",
  "Keck Graduate Institute",
  "Keene State College",
  "Keiser University",
  "Kendall College",
  "Kennesaw State University",
  "Kent State University",
  "Kentucky Christian University",
  "Kentucky State University",
  "Kentucky Wesleyan College",
  "Kenyon College",
  "Kettering College",
  "Kettering University",
  "Keuka College",
  "Keystone College",
  "King University",
  "King's College",
  "Knox College",
  "Kutztown University of Pennsylvania",
  "Kuyper College",
  "La Roche University",
  "La Salle University",
  "La Sierra University",
  "Labouré College",
  "Lafayette College",
  "LaGrange College",
  "Laguna College of Art and Design",
  "Lake Erie College",
  "Lake Forest College",
  "Lake Forest Graduate School of Management",
  "Lake Superior State University",
  "Lake Washington Institute of Technology",
  "Lakeland College",
  "Lakeview College of Nursing",
  "Lamar University",
  "Lancaster Bible College",
  "Lander University",
  "Landmark College",
  "Lane College",
  "Langston University",
  "Lasell College",
  "Lawrence Technological University",
  "Lawrence University",
  "Le Moyne College",
  "Lebanon Valley College",
  "Lee University",
  "Lees-McRae College",
  "Lehigh University",
  "Lehman College, CUNY",
  "LeMoyne-Owen College",
  "Lenoir-Rhyne University",
  "Lesley University",
  "LeTourneau University",
  "Lewis & Clark College",
  "Lewis University",
  "Lewis-Clark State College",
  "Liberty University",
  "Life Pacific College",
  "Life University",
  "LIM College",
  "Limestone College",
  "Lincoln Christian University",
  "Lincoln College",
  "Lincoln Memorial University",
  "Lincoln University",
  "Lincoln University, California",
  "Lincoln University, Missouri",
  "Lindenwood University",
  "Lindsey Wilson College",
  "Linfield College",
  "Lipscomb University",
  "Livingstone College",
  "Lock Haven University",
  "Logan University",
  "Loma Linda University",
  "Long Island University",
  "Longwood University",
  "Loras College",
  "Louisiana College",
  "Louisiana State University",
  "Louisiana State University Health Sciences Center",
  "Louisiana State University Health Sciences Center-Shreveport",
  "Louisiana State University in Shreveport",
  "Louisiana State University of Alexandria",
  "Louisiana Tech University",
  "Lourdes University",
  "Loyola Marymount University",
  "Loyola University Chicago",
  "Loyola University Maryland",
  "Loyola University New Orleans",
  "Lubbock Christian University",
  "Luther College",
  "Lycoming College",
  "Lynn University",
  "Lyon College",
  "Macalester College",
  "MacMurray College",
  "Madonna University",
  "Magdalen College of the Liberal Arts",
  "Maharishi University of Management",
  "Maine College of Art",
  "Maine Maritime Academy",
  "Malone University",
  "Manchester University",
  "Manhattan Christian College",
  "Manhattan College",
  "Manhattan School of Music",
  "Manhattanville College",
  "Mansfield University of Pennsylvania",
  "Maranatha Baptist University",
  "Maria College",
  "Marian University",
  "Marian University, Wisconsin",
  "Marietta College",
  "Marist College",
  "Marlboro College",
  "Marquette University",
  "Mars Hill University",
  "Marshall B. Ketchum University",
  "Marshall University",
  "Martin Luther College",
  "Martin Methodist College",
  "Martin University",
  "Mary Baldwin University",
  "Marygrove College",
  "Maryland Institute College of Art",
  "Maryland University of Integrative Health",
  "Marymount California University",
  "Marymount Manhattan College",
  "Marymount University",
  "Maryville College",
  "Maryville University",
  "Marywood University",
  "Massachusetts College of Art and Design",
  "Massachusetts College of Liberal Arts",
  "Massachusetts Institute of Technology",
  "Massachusetts Maritime Academy",
  "Massachusetts School of Law",
  "Mayo Medical School",
  "Mayville State University",
  "McDaniel College",
  "McKendree University",
  "McMurry University",
  "McNeese State University",
  "McPherson College",
  "MCPHS University",
  "Medaille College",
  "Medgar Evers College",
  "Medical College of Wisconsin",
  "Medical University of South Carolina",
  "Meharry Medical College",
  "Memphis College of Art",
  "Menlo College",
  "Mercer University",
  "Mercy College",
  "Mercy College of Health Sciences",
  "Mercy College of Ohio",
  "Mercyhurst University",
  "Meredith College",
  "Merrimack College",
  "Messiah College",
  "Methodist College",
  "Methodist University",
  "Metropolitan College of New York",
  "Metropolitan State University",
  "Metropolitan State University of Denver",
  "MGH Institute of Health Professions",
  "Miami Dade College",
  "Miami University",
  "Michigan School of Psychology",
  "Michigan State University",
  "Michigan Technological University",
  "Mid-America Christian University",
  "MidAmerica Nazarene University",
  "Mid-Atlantic Christian University",
  "Middle Georgia State University",
  "Middle Tennessee School of Anesthesia",
  "Middle Tennessee State University",
  "Middlebury College",
  "Midland College",
  "Midland University",
  "Midstate College",
  "Midway University",
  "Midwestern State University",
  "Midwestern University",
  "Miles College",
  "Millersville University of Pennsylvania",
  "Milligan College",
  "Millikin University",
  "Mills College",
  "Millsaps College",
  "Milwaukee Institute of Art & Design",
  "Milwaukee School of Engineering",
  "Minneapolis College of Art and Design",
  "Minnesota State University Moorhead",
  "Minnesota State University, Mankato",
  "Minot State University",
  "Misericordia University",
  "Mississippi College",
  "Mississippi State University",
  "Mississippi University for Women",
  "Mississippi Valley State University",
  "Missouri Baptist University",
  "Missouri Southern State University",
  "Missouri State University",
  "Missouri University of Science and Technology",
  "Missouri Valley College",
  "Missouri Western State University",
  "Mitchell College",
  "Mitchell Hamline School of Law",
  "Molloy College",
  "Monmouth College",
  "Monmouth University",
  "Monroe College",
  "Montana State University",
  "Montana State University Billings",
  "Montana State University-Northern",
  "Montana Tech",
  "Montclair State University",
  "Montreat College",
  "Montserrat College of Art",
  "Moody Bible Institute",
  "Moore College of Art and Design",
  "Moravian College",
  "Morehead State University",
  "Morehouse College",
  "Morehouse School of Medicine",
  "Morgan State University",
  "Morningside College",
  "Morris College",
  "Morrisville State College",
  "Mount Aloysius College",
  "Mount Carmel College of Nursing",
  "Mount Holyoke College",
  "Mount Marty College",
  "Mount Mary University",
  "Mount Mercy University",
  "Mount Saint Mary College",
  "Mount Saint Mary's University",
  "Mount St. Joseph University",
  "Mount St. Mary's University",
  "Mount Vernon Nazarene University",
  "Muhlenberg College",
  "Multnomah University",
  "Murray State University",
  "Muskingum University",
  "Naropa University",
  "National American University",
  "National Defense University",
  "National Louis University",
  "National University",
  "National University of Health Sciences",
  "Naval Postgraduate School",
  "Nazarene Bible College",
  "Nazareth College",
  "Nebraska Christian College",
  "Nebraska Methodist College",
  "Nebraska Wesleyan University",
  "Neumann University",
  "Neumont University",
  "Nevada State College",
  "New College of Florida",
  "New England College",
  "New England College of Business",
  "New England College of Optometry",
  "New England Conservatory",
  "New England Institute of Technology",
  "New England Law | Boston",
  "New Hope Christian College",
  "New Jersey City University",
  "New Jersey Institute of Technology",
  "New Mexico Highlands University",
  "New Mexico Institute of Mining and Technology",
  "New Mexico State University",
  "New York Academy of Art",
  "New York City College of Technology, CUNY",
  "New York College of Podiatric Medicine",
  "New York Institute of Technology",
  "New York Law School",
  "New York Medical College",
  "New York School of Interior Design",
  "New York University",
  "Newberry College",
  "Newman University",
  "NewSchool of Architecture and Design",
  "Niagara University",
  "Nicholls State University",
  "Nichols College",
  "Norfolk State University",
  "North Carolina Agricultural and Technical State University",
  "North Carolina Central University",
  "North Carolina State University",
  "North Carolina Wesleyan College",
  "North Central College",
  "North Central University",
  "North Dakota State University",
  "North Greenville University",
  "North Park University",
  "Northeast Ohio Medical University",
  "Northeastern Illinois University",
  "Northeastern State University",
  "Northeastern University",
  "Northern Arizona University",
  "Northern Illinois University",
  "Northern Kentucky University",
  "Northern Michigan University",
  "Northern New Mexico College",
  "Northern State University",
  "Northern Vermont University",
  "Northland College",
  "Northpoint Bible College",
  "Northwest Christian University",
  "Northwest Florida State College",
  "Northwest Missouri State University",
  "Northwest Nazarene University",
  "Northwest University",
  "Northwestern College",
  "Northwestern Health Sciences University",
  "Northwestern Oklahoma State University",
  "Northwestern State University of Louisiana",
  "Northwestern University",
  "Northwood University",
  "Norwich University",
  "Notre Dame College",
  "Notre Dame de Namur University",
  "Notre Dame of Maryland University",
  "Nova Southeastern University",
  "Nyack College",
  "Oak Hills Christian College",
  "Oakland City University",
  "Oakland University",
  "Oakwood University",
  "Oberlin College",
  "Occidental College",
  "Oglethorpe University",
  "Ohio Christian University",
  "Ohio Dominican University",
  "Ohio Northern University",
  "Ohio University",
  "Ohio University Southern",
  "Ohio University-Chillicothe",
  "Ohio Valley University",
  "Ohio Wesleyan University",
  "Oklahoma Baptist University",
  "Oklahoma Christian University",
  "Oklahoma City University",
  "Oklahoma Panhandle State University",
  "Oklahoma State University",
  "Oklahoma Wesleyan University",
  "Old Dominion University",
  "Olivet College",
  "Olivet Nazarene University",
  "Oral Roberts University",
  "Oregon Health & Science University",
  "Oregon Institute of Technology",
  "Oregon State University",
  "Otis College of Art and Design",
  "Ottawa University",
  "Otterbein University",
  "Ouachita Baptist University",
  "Our Lady of the Lake University",
  "Ozark Christian College",
  "Pace University",
  "Pacific Lutheran University",
  "Pacific Northwest College of Art",
  "Pacific Oaks College",
  "Pacific Union College",
  "Pacific University",
  "Pacifica Graduate Institute",
  "Paine College",
  "Palm Beach Atlantic University",
  "Palm Beach State College",
  "Palo Alto University",
  "Pardee RAND Graduate School",
  "Park University",
  "Parker University",
  "Paul Quinn College",
  "Paul Smith's College",
  "Peirce College",
  "Penn State College of Medicine",
  "Penn State University",
  "Pennsylvania Academy of the Fine Arts",
  "Pennsylvania College of Art and Design",
  "Pennsylvania College of Health Sciences",
  "Pennsylvania College of Technology",
  "Pensacola State College",
  "Pepperdine University",
  "Peru State College",
  "Pfeiffer University",
  "Philander Smith College",
  "Phillips Graduate University",
  "Piedmont College",
  "Piedmont International University",
  "Pillar College",
  "Pine Manor College",
  "Pittsburg State University",
  "Pitzer College",
  "Plaza College",
  "Plymouth State University",
  "Point Loma Nazarene University",
  "Point Park University",
  "Point University",
  "Polk State College",
  "Pomona College",
  "Pontifical Faculty of the Immaculate Conception",
  "Portland State University",
  "Post University",
  "Prairie View A&M University",
  "Pratt Institute",
  "Presbyterian College",
  "Prescott College",
  "Presentation College",
  "Princeton University",
  "Principia College",
  "Providence Christian College",
  "Providence College",
  "Purchase College, State University of New York",
  "Purdue University",
  "Purdue University Fort Wayne",
  "Purdue University Global",
  "Purdue University Northwest",
  "Queens College, City University of New York",
  "Queens University of Charlotte",
  "Quincy University",
  "Quinnipiac University",
  "Radford University",
  "Ramapo College of New Jersey",
  "Randolph College",
  "Randolph-Macon College",
  "Rasmussen College",
  "Reed College",
  "Regent University",
  "Regis College",
  "Regis University",
  "Reinhardt University",
  "Relay Graduate School of Education",
  "Rensselaer Polytechnic Institute",
  "Research College of Nursing",
  "Resurrection University",
  "Rhode Island College",
  "Rhode Island School of Design",
  "Rhodes College",
  "Rice University",
  "Richmont Graduate University",
  "Rider University",
  "Ringling College of Art and Design",
  "Ripon College",
  "Rivier University",
  "Roanoke College",
  "Robert Morris University",
  "Robert Morris University Illinois",
  "Roberts Wesleyan College",
  "Rochester Institute of Technology",
  "Rochester University",
  "Rockford University",
  "Rockhurst University",
  "Rocky Mountain College",
  "Rocky Mountain College of Art and Design",
  "Rocky Mountain University of Health Professions",
  "Rocky Vista University",
  "Roger Williams University",
  "Rogers State University",
  "Rollins College",
  "Roosevelt University",
  "Rosalind Franklin University of Medicine and Science",
  "Rose-Hulman Institute of Technology",
  "Roseman University of Health Sciences",
  "Rosemont College",
  "Rowan University",
  "Rush University",
  "Rust College",
  "Rutgers, The State University of New Jersey",
  "Sacred Heart University",
  "Saginaw Valley State University",
  "Saint Ambrose University",
  "Saint Anselm College",
  "Saint Anthony College of Nursing",
  "Saint Augustine College",
  "Saint Francis Medical Center College of Nursing",
  "Saint Francis University",
  "Saint Johns River State College",
  "Saint Joseph's College of Maine",
  "Saint Joseph's University",
  "Saint Leo University",
  "Saint Louis University",
  "Saint Luke's College of Health Sciences",
  "Saint Martin's University",
  "Saint Mary-of-the-Woods College",
  "Saint Mary's College",
  "Saint Mary's College of California",
  "Saint Mary's University of Minnesota",
  "Saint Michael's College",
  "Saint Peter's University",
  "Saint Vincent College",
  "Saint Xavier University",
  "Salem College",
  "Salem International University",
  "Salem State University",
  "Salisbury University",
  "Salus University",
  "Salve Regina University",
  "Sam Houston State University",
  "Samford University",
  "Samuel Merritt University",
  "San Diego Christian College",
  "San Diego State University",
  "San Francisco Art Institute",
  "San Francisco Conservatory of Music",
  "San Francisco State University",
  "San Joaquin College of Law",
  "San José State University",
  "Santa Clara University",
  "Santa Fe College",
  "Sarah Lawrence College",
  "Savannah College of Art and Design",
  "Savannah State University",
  "Saybrook University",
  "Schiller International University",
  "School of the Art Institute of Chicago",
  "School of Visual Arts",
  "Schreiner University",
  "Scripps College",
  "Seattle Central College",
  "Seattle Pacific University",
  "Seattle University",
  "Seminole State College of Florida",
  "Seton Hall University",
  "Seton Hill University",
  "Sewanee: The University of the South",
  "Shaw University",
  "Shawnee State University",
  "Shenandoah University",
  "Shepherd University",
  "Shippensburg University of Pennsylvania",
  "Shorter University",
  "Siena College",
  "Siena Heights University",
  "Sierra Nevada College",
  "Simmons University",
  "Simpson College",
  "Simpson University",
  "SIT Graduate Institute",
  "Skidmore College",
  "Slippery Rock University of Pennsylvania",
  "Smith College",
  "Snow College",
  "Sofia University",
  "Soka University of America",
  "Sonoma State University",
  "South Carolina State University",
  "South College",
  "South Dakota School of Mines and Technology",
  "South Dakota State University",
  "South Georgia State College",
  "South Texas College",
  "South Texas College of Law",
  "South University",
  "Southeast Missouri State University",
  "Southeastern Baptist College",
  "Southeastern Louisiana University",
  "Southeastern Oklahoma State University",
  "Southeastern University",
  "Southern Adventist University",
  "Southern Arkansas University",
  "Southern California Institute of Architecture",
  "Southern College of Optometry",
  "Southern Connecticut State University",
  "Southern Illinois University Carbondale",
  "Southern Illinois University Edwardsville",
  "Southern Methodist University",
  "Southern Nazarene University",
  "Southern New Hampshire University",
  "Southern Oregon University",
  "Southern University and A&M College",
  "Southern University at New Orleans",
  "Southern University Law Center",
  "Southern Utah University",
  "Southern Vermont College",
  "Southern Virginia University",
  "Southern Wesleyan University",
  "Southwest Baptist University",
  "Southwest Minnesota State University",
  "Southwest University of Visual Arts",
  "Southwestern Adventist University",
  "Southwestern Assemblies of God University",
  "Southwestern Christian University",
  "Southwestern College",
  "Southwestern College, Santa Fe",
  "Southwestern Law School",
  "Southwestern Oklahoma State University",
  "Southwestern University",
  "Spalding University",
  "Spelman College",
  "Spring Arbor University",
  "Spring Hill College",
  "Springfield College",
  "St Andrews University",
  "St John's College",
  "St Petersburg College",
  "St Thomas University",
  "St. Augustine's University",
  "St. Bonaventure University",
  "St. Catherine University",
  "St. Cloud State University",
  "St. Edward's University",
  "St. Francis College",
  "St. John Fisher College",
  "St. John's University",
  "St. Joseph's College",
  "St. Lawrence University",
  "St. Louis Christian College",
  "St. Louis College of Pharmacy",
  "St. Mary's College of Maryland",
  "St. Mary's University",
  "St. Norbert College",
  "St. Olaf College",
  "St. Thomas Aquinas College",
  "Stanford University",
  "State College of Florida-Manatee-Sarasota",
  "State University of New York at Fredonia",
  "State University of New York at New Paltz",
  "State University of New York at Oswego",
  "State University of New York College at Plattsburgh",
  "Stephen F. Austin State University",
  "Stephens College",
  "Sterling College",
  "Sterling College, Vermont",
  "Stetson University",
  "Stevens Institute of Technology",
  "Stevenson University",
  "Stillman College",
  "Stockton University",
  "Stonehill College",
  "Stony Brook University",
  "Strayer University",
  "Suffolk University",
  "Sul Ross State University",
  "Sullivan University",
  "SUNY Canton",
  "SUNY Cobleskill",
  "SUNY College at Old Westbury",
  "SUNY College at Oneonta",
  "SUNY College of Environmental Science and Forestry",
  "SUNY College of Optometry",
  "SUNY Cortland",
  "SUNY Delhi",
  "SUNY Downstate Medical Center",
  "SUNY Empire State College",
  "SUNY Geneseo",
  "SUNY Maritime College",
  "SUNY Polytechnic Institute",
  "SUNY Upstate Medical University",
  "Susquehanna University",
  "Swarthmore College",
  "Sweet Briar College",
  "Syracuse University",
  "Tabor College",
  "Talladega College",
  "Tarleton State University",
  "Taylor University",
  "Temple University",
  "Tennessee State University",
  "Tennessee Tech University",
  "Tennessee Wesleyan University",
  "Texas A&M International University",
  "Texas A&M University",
  "Texas A&M University at Galveston",
  "Texas A&M University-Commerce",
  "Texas A&M University-Corpus Christi",
  "Texas A&M University-Kingsville",
  "Texas A&M University-Texarkana",
  "Texas Christian University",
  "Texas College",
  "Texas Lutheran University",
  "Texas Southern University",
  "Texas State University",
  "Texas Tech University",
  "Texas Tech University Health Sciences Center",
  "Texas Wesleyan University",
  "Texas Woman's University",
  "The American College of Financial Services",
  "The Baptist College of Florida",
  "The Catholic University of America",
  "The Chicago School of Professional Psychology",
  "The Citadel, The Military College of South Carolina",
  "The City College of New York",
  "The College at Brockport",
  "The College of Idaho",
  "The College of New Jersey",
  "The College of Saint Rose",
  "The College of St. Scholastica",
  "The College of Westchester",
  "The College of Wooster",
  "The Cooper Union for the Advancement of Science and Art",
  "The Culinary Institute of America",
  "The Evergreen State College",
  "The Graduate Center, CUNY",
  "The Institute of World Politics",
  "The Juilliard School",
  "The King's College",
  "The King's University",
  "The Master's University",
  "The New School",
  "The Ohio State University",
  "The Rockefeller University",
  "The Sage Colleges",
  "The School of Architecture at Taliesin",
  "The State University of New York at Potsdam",
  "The University of Alabama",
  "The University of Alabama in Huntsville",
  "The University of Arizona",
  "The University of Findlay",
  "The University of Maine",
  "The University of Memphis",
  "The University of Montana",
  "The University of Montana Western",
  "The University of Oklahoma",
  "The University of Oklahoma Health Sciences Center",
  "The University of Tampa",
  "The University of Tennessee at Chattanooga",
  "The University of Tennessee at Martin",
  "The University of Tennessee Health Science Center",
  "The University of Tennessee, Knoxville",
  "The University of Texas at Arlington",
  "The University of Texas at Austin",
  "The University of Texas at Dallas",
  "The University of Texas at El Paso",
  "The University of Texas at San Antonio",
  "The University of Texas at Tyler",
  "The University of Texas Health Science Center at Houston",
  "The University of Texas Health Science Center at San Antonio",
  "The University of Texas Health Science Center at Tyler",
  "The University of Texas MD Anderson Cancer Center",
  "The University of Texas Medical Branch at Galveston",
  "The University of Texas of the Permian Basin",
  "The University of the Arts",
  "The University of Tulsa",
  "The University of Utah",
  "The University of Virginia's College at Wise",
  "The University of West Alabama",
  "The University of West Los Angeles",
  "The Wright Institute",
  "Thiel College",
  "Thomas Aquinas College",
  "Thomas College",
  "Thomas Edison State University",
  "Thomas Jefferson School of Law",
  "Thomas Jefferson University",
  "Thomas More College of Liberal Arts",
  "Thomas More University",
  "Thomas University",
  "Tiffin University",
  "Toccoa Falls College",
  "Tougaloo College",
  "Touro College",
  "Touro University California",
  "Towson University",
  "Transylvania University",
  "Trevecca Nazarene University",
  "Trine University",
  "Trinity Bible College",
  "Trinity Christian College",
  "Trinity College",
  "Trinity College of Florida",
  "Trinity College of Nursing & Health Sciences",
  "Trinity International University",
  "Trinity University",
  "Trinity Washington University",
  "Tri-State Bible College",
  "Trocaire College",
  "Troy University",
  "Truett McConnell University",
  "Truman State University",
  "Tufts University",
  "Tulane University",
  "Tusculum College",
  "Tuskegee University",
  "Uniformed Services University of the Health Sciences",
  "Union College",
  "Union College, Kentucky",
  "Union College, Nebraska",
  "Union Institute & University",
  "Union University",
  "United States Air Force Academy",
  "United States Coast Guard Academy",
  "United States Merchant Marine Academy",
  "United States Military Academy",
  "United States Naval Academy",
  "United States Sports Academy",
  "United States University",
  "Unity College",
  "University at Albany, State University of New York",
  "University at Buffalo, State University of New York",
  "University of Advancing Technology",
  "University of Akron",
  "University of Alabama at Birmingham",
  "University of Alaska Anchorage",
  "University of Alaska Fairbanks",
  "University of Alaska Southeast",
  "University of Arkansas",
  "University of Arkansas - Fort Smith",
  "University of Arkansas at Little Rock",
  "University of Arkansas at Monticello",
  "University of Arkansas at Pine Bluff",
  "University of Arkansas for Medical Sciences",
  "University of Baltimore",
  "University of Bridgeport",
  "University of California, Berkeley",
  "University of California, Davis",
  "University of California, Hastings College of the Law",
  "University of California, Irvine",
  "University of California, Los Angeles",
  "University of California, Merced",
  "University of California, Riverside",
  "University of California, San Diego",
  "University of California, San Francisco",
  "University of California, Santa Barbara",
  "University of California, Santa Cruz",
  "University of Central Arkansas",
  "University of Central Florida",
  "University of Central Missouri",
  "University of Central Oklahoma",
  "University of Charleston",
  "University of Chicago",
  "University of Cincinnati",
  "University of Colorado Boulder",
  "University of Colorado Colorado Springs",
  "University of Colorado Denver",
  "University of Connecticut",
  "University of Dallas",
  "University of Dayton",
  "University of Delaware",
  "University of Denver",
  "University of Detroit Mercy",
  "University of Dubuque",
  "University of Evansville",
  "University of Florida",
  "University of Georgia",
  "University of Hartford",
  "University of Hawaii at Hilo",
  "University of Hawaii at Manoa",
  "University of Hawaii-West Oahu",
  "University of Holy Cross",
  "University of Houston",
  "University of Houston-Clear Lake",
  "University of Houston-Downtown",
  "University of Houston-Victoria",
  "University of Idaho",
  "University of Illinois at Chicago",
  "University of Illinois at Springfield",
  "University of Illinois at Urbana-Champaign",
  "University of Indianapolis",
  "University of Iowa",
  "University of Jamestown",
  "University of Kansas",
  "University of Kentucky",
  "University of La Verne",
  "University of Louisiana at Lafayette",
  "University of Louisiana at Monroe",
  "University of Louisville",
  "University of Lynchburg",
  "University of Maine at Augusta",
  "University of Maine at Farmington",
  "University of Maine at Fort Kent",
  "University of Maine at Machias",
  "University of Maine at Presque Isle",
  "University of Mary",
  "University of Mary Hardin-Baylor",
  "University of Mary Washington",
  "University of Maryland",
  "University of Maryland Eastern Shore",
  "University of Maryland, Baltimore",
  "University of Maryland, Baltimore County",
  "University of Massachusetts Amherst",
  "University of Massachusetts Boston",
  "University of Massachusetts Dartmouth",
  "University of Massachusetts Lowell",
  "University of Massachusetts Medical School",
  "University of Miami",
  "University of Michigan",
  "University of Michigan-Dearborn",
  "University of Michigan-Flint",
  "University of Minnesota",
  "University of Mississippi",
  "University of Mississippi Medical Center",
  "University of Missouri",
  "University of Missouri-Kansas City",
  "University of Missouri-St. Louis",
  "University of Mobile",
  "University of Montevallo",
  "University of Mount Olive",
  "University of Mount Union",
  "University of Nebraska at Kearney",
  "University of Nebraska at Omaha",
  "University of Nebraska Medical Center",
  "University of Nebraska-Lincoln",
  "University of Nevada, Las Vegas",
  "University of Nevada, Reno",
  "University of New England",
  "University of New Hampshire",
  "University of New Haven",
  "University of New Mexico",
  "University of New Orleans",
  "University of North Alabama",
  "University of North Carolina at Asheville",
  "University of North Carolina at Chapel Hill",
  "University of North Carolina at Charlotte",
  "University of North Carolina at Greensboro",
  "University of North Carolina at Pembroke",
  "University of North Carolina School of the Arts",
  "University of North Carolina Wilmington",
  "University of North Dakota",
  "University of North Florida",
  "University of North Georgia",
  "University of North Texas",
  "University of North Texas Health Science Center",
  "University of Northern Colorado",
  "University of Northern Iowa",
  "University of Northwestern - St. Paul",
  "University of Northwestern Ohio",
  "University of Notre Dame",
  "University of Oregon",
  "University of Pennsylvania",
  "University of Pikeville",
  "University of Pittsburgh",
  "University of Portland",
  "University of Providence",
  "University of Puget Sound",
  "University of Redlands",
  "University of Rhode Island",
  "University of Richmond",
  "University of Rio Grande",
  "University of Rochester",
  "University of Saint Francis",
  "University of Saint Joseph",
  "University of Saint Mary",
  "University of San Diego",
  "University of San Francisco",
  "University of Science and Arts of Oklahoma",
  "University of Scranton",
  "University of Sioux Falls",
  "University of South Alabama",
  "University of South Carolina",
  "University of South Carolina-Aiken",
  "University of South Carolina-Beaufort",
  "University of South Carolina-Upstate",
  "University of South Dakota",
  "University of South Florida",
  "University of Southern California",
  "University of Southern Indiana",
  "University of Southern Maine",
  "University of Southern Mississippi",
  "University of St Thomas",
  "University of St. Augustine for Health Sciences",
  "University of St. Francis",
  "University of St. Thomas",
  "University of Texas Rio Grande Valley",
  "University of Texas Southwestern Medical Center",
  "University of the Cumberlands",
  "University of the District of Columbia",
  "University of the Incarnate Word",
  "University of the Ozarks",
  "University of the Pacific",
  "University of the Potomac",
  "University of the Sciences in Philadelphia",
  "University of the Southwest",
  "University of the West",
  "University of Toledo",
  "University of Valley Forge",
  "University of Vermont",
  "University of Virginia",
  "University of Washington",
  "University of West Florida",
  "University of West Georgia",
  "University of Western States",
  "University of Wisconsin-Eau Claire",
  "University of Wisconsin-Green Bay",
  "University of Wisconsin-La Crosse",
  "University of Wisconsin-Madison",
  "University of Wisconsin-Milwaukee",
  "University of Wisconsin-Oshkosh",
  "University of Wisconsin-Parkside",
  "University of Wisconsin-Platteville",
  "University of Wisconsin-River Falls",
  "University of Wisconsin-Stevens Point",
  "University of Wisconsin-Stout",
  "University of Wisconsin-Superior",
  "University of Wisconsin-Whitewater",
  "University of Wyoming",
  "Upper Iowa University",
  "Urbana University",
  "Ursinus College",
  "Ursuline College",
  "Utah State University",
  "Utah Valley University",
  "Utica College",
  "Valdosta State University",
  "Valencia College",
  "Valley City State University",
  "Valparaiso University",
  "Vanderbilt University",
  "VanderCook College of Music",
  "Vanguard University of Southern California",
  "Vassar College",
  "Vaughn College of Aeronautics and Technology",
  "Vermont College of Fine Arts",
  "Vermont Law School",
  "Vermont Technical College",
  "Villa Maria College",
  "Villanova University",
  "Vincennes University",
  "Virginia Commonwealth University",
  "Virginia Military Institute",
  "Virginia Polytechnic Institute and State University",
  "Virginia State University",
  "Virginia Union University",
  "Virginia Wesleyan University",
  "Viterbo University",
  "Voorhees College",
  "Wabash College",
  "Wade College",
  "Wagner College",
  "Wake Forest University",
  "Waldorf University",
  "Walla Walla University",
  "Walsh College",
  "Walsh University",
  "Warner Pacific College",
  "Warner University",
  "Warren Wilson College",
  "Wartburg College",
  "Washburn University",
  "Washington & Jefferson College",
  "Washington Adventist University",
  "Washington and Lee University",
  "Washington College",
  "Washington State University",
  "Washington University in St. Louis",
  "Watkins College of Art, Design & Film",
  "Wayland Baptist University",
  "Wayne State College",
  "Wayne State University",
  "Waynesburg University",
  "Webb Institute",
  "Webber International University",
  "Weber State University",
  "Webster University",
  "Welch College",
  "Wellesley College",
  "Wells College",
  "Wentworth Institute of Technology",
  "Wesley College",
  "Wesleyan College",
  "Wesleyan University",
  "West Chester University of Pennsylvania",
  "West Coast University-Los Angeles",
  "West Liberty University",
  "West Texas A&M University",
  "West Virginia School of Osteopathic Medicine",
  "West Virginia State University",
  "West Virginia University",
  "West Virginia University at Parkersburg",
  "West Virginia University Institute of Technology",
  "West Virginia Wesleyan College",
  "Western Carolina University",
  "Western Colorado University",
  "Western Connecticut State University",
  "Western Illinois University",
  "Western Kentucky University",
  "Western Michigan University",
  "Western Nevada College",
  "Western New England University",
  "Western New Mexico University",
  "Western Oregon University",
  "Western State College of Law",
  "Western University of Health Sciences",
  "Western Washington University",
  "Westfield State University",
  "Westminster College",
  "Westminster College, Missouri",
  "Westminster College, Pennsylvania",
  "Westmont College",
  "Wheaton College",
  "Wheaton College, Massachusetts",
  "Wheeling University",
  "Whitman College",
  "Whittier College",
  "Whitworth University",
  "Wichita State University",
  "Widener University",
  "Wilberforce University",
  "Wiley College",
  "Wilkes University",
  "Willamette University",
  "William Carey University",
  "William James College",
  "William Jessup University",
  "William Jewell College",
  "William Paterson University",
  "William Peace University",
  "William Penn University",
  "William Woods University",
  "Williams Baptist University",
  "Williams College",
  "Wilmington College",
  "Wilmington University",
  "Wilson College",
  "Wingate University",
  "Winona State University",
  "Winston-Salem State University",
  "Winthrop University",
  "Wisconsin Lutheran College",
  "Wisconsin School of Professional Psychology",
  "Wittenberg University",
  "Wofford College",
  "WON Institute of Graduate Studies",
  "Woodbury University",
  "Worcester Polytechnic Institute",
  "Worcester State University",
  "Wright State University",
  "Xavier University",
  "Xavier University of Louisiana",
  "Yale University",
  "Yeshiva University",
  "York College",
  "York College of Pennsylvania",
  "York College, City University of New York",
  "Young Harris College",
  "Youngstown State University",
];

const Majors = [
  "Accounting",
  "Actuarial Science",
  "Advertising",
  "Aerospace Engineering",
  "African Languages, Literatures, and Linguistics",
  "African Studies",
  "African-American Studies",
  "Agricultural Business and Management",
  "Agricultural Economics",
  "Agricultural Education",
  "Agricultural Journalism",
  "Agricultural Mechanization",
  "Agricultural Technology Management",
  "Agricultural/Biological Engineering and Bioengineering",
  "Agriculture",
  "Agronomy and Crop Science",
  "Air Traffic Control",
  "American History",
  "American Literature",
  "American Sign Language",
  "American Studies",
  "Anatomy",
  "Ancient Studies",
  "Animal Behavior and Ethology",
  "Animal Science",
  "Animation and Special Effects",
  "Anthropology",
  "Applied Mathematics",
  "Aquaculture",
  "Aquatic Biology",
  "Arabic",
  "Archeology",
  "Architectural Engineering",
  "Architectural History",
  "Architecture",
  "Art",
  "Art Education",
  "Art History",
  "Art Therapy",
  "Artificial Intelligence and Robotics",
  "Asian-American Studies",
  "Astronomy",
  "Astrophysics",
  "Athletic Training",
  "Atmospheric Science",
  "Automotive Engineering",
  "Aviation",
  "Bakery Science",
  "Biblical Studies",
  "Biochemistry",
  "Bioethics",
  "Biology",
  "Biomedical Engineering",
  "Biomedical Science",
  "Biopsychology",
  "Biotechnology",
  "Botany/Plant Biology",
  "Business Administration/Management",
  "Business Communications",
  "Business Education",
  "Canadian Studies",
  "Caribbean Studies",
  "Cell Biology",
  "Ceramic Engineering",
  "Ceramics",
  "Chemical Engineering",
  "Chemical Physics",
  "Chemistry",
  "Child Care",
  "Child Development",
  "Chinese",
  "Chiropractic",
  "Church Music",
  "Cinematography and Film/Video Production",
  "Circulation Technology",
  "Civil Engineering",
  "Classics",
  "Clinical Psychology",
  "Cognitive Psychology",
  "Communication Disorders",
  "Communications Studies/Speech Communication and Rhetoric",
  "Comparative Literature",
  "Computer and Information Science",
  "Computer Science",
  "Computer Engineering",
  "Computer Graphics",
  "Computer Systems Analysis",
  "Construction Management",
  "Counseling",
  "Crafts",
  "Creative Writing",
  "Criminal Science",
  "Criminology",
  "Culinary Arts",
  "Dance",
  "Data Processing",
  "Dental Hygiene",
  "Developmental Psychology",
  "Diagnostic Medical Sonography",
  "Dietetics",
  "Digital Communications and Media/Multimedia",
  "Drawing",
  "Early Childhood Education",
  "East Asian Studies",
  "East European Studies",
  "Ecology",
  "Economics",
  "Education",
  "Education Administration",
  "Education of the Deaf",
  "Educational Psychology",
  "Electrical Engineering",
  "Elementary Education",
  "Engineering Mechanics",
  "Engineering Physics",
  "English",
  "English Composition",
  "English Literature",
  "Entomology",
  "Entrepreneurship",
  "Environmental Design/Architecture",
  "Environmental Science",
  "Environmental/Environmental Health Engineering",
  "Epidemiology",
  "Equine Studies",
  "Ethnic Studies",
  "European History",
  "Experimental Pathology",
  "Experimental Psychology",
  "Fashion Design",
  "Fashion Merchandising",
  "Feed Science",
  "Fiber, Textiles, and Weaving Arts",
  "Film",
  "Finance",
  "Floriculture",
  "Food Science",
  "Forensic Science",
  "Forestry",
  "French",
  "Furniture Design",
  "Game Design",
  "Gay and Lesbian Studies",
  "Genetics",
  "Geography",
  "Geological Engineering",
  "Geology",
  "Geophysics",
  "German",
  "Gerontology",
  "Government",
  "Graphic Design",
  "Health Administration",
  "Hebrew",
  "Hispanic-American, Puerto Rican, and Chicano Studies",
  "Historic Preservation",
  "History",
  "Home Economics",
  "Horticulture",
  "Hospitality",
  "Human Development",
  "Human Resources Management",
  "Illustration",
  "Industrial Design",
  "Industrial Engineering",
  "Industrial Management",
  "Industrial Psychology",
  "Information Technology",
  "Interior Architecture",
  "Interior Design",
  "International Agriculture",
  "International Business",
  "International Relations",
  "International Studies",
  "Islamic Studies",
  "Italian",
  "Japanese",
  "Jazz Studies",
  "Jewelry and Metalsmithing",
  "Jewish Studies",
  "Journalism",
  "Kinesiology",
  "Korean",
  "Land Use Planning and Management",
  "Landscape Architecture",
  "Landscape Horticulture",
  "Latin American Studies",
  "Library Science",
  "Linguistics",
  "Logistics Management",
  "Management Information Systems",
  "Managerial Economics",
  "Marine Biology",
  "Marine Science",
  "Marketing",
  "Mass Communication",
  "Massage Therapy",
  "Materials Science",
  "Mathematics",
  "Mechanical Engineering",
  "Medical Technology",
  "Medieval and Renaissance Studies",
  "Mental Health Services",
  "Merchandising and Buying Operations",
  "Metallurgical Engineering",
  "Microbiology",
  "Middle Eastern Studies",
  "Military Science",
  "Mineral Engineering",
  "Missions",
  "Modern Greek",
  "Molecular Biology",
  "Molecular Genetics",
  "Mortuary Science",
  "Museum Studies",
  "Music",
  "Music Education",
  "Music History",
  "Music Management",
  "Music Therapy",
  "Musical Theater",
  "Native American Studies",
  "Natural Resources Conservation",
  "Naval Architecture",
  "Neurobiology",
  "Neuroscience",
  "Nuclear Engineering",
  "Nursing",
  "Nutrition",
  "Occupational Therapy",
  "Ocean Engineering",
  "Oceanography",
  "Operations Management",
  "Organizational Behavior Studies",
  "Painting",
  "Paleontology",
  "Pastoral Studies",
  "Peace Studies",
  "Petroleum Engineering",
  "Pharmacology",
  "Pharmacy",
  "Philosophy",
  "Photography",
  "Photojournalism",
  "Physical Education",
  "Physical Therapy",
  "Physician Assistant",
  "Physics",
  "Physiological Psychology",
  "Piano",
  "Planetary Science",
  "Plant Pathology",
  "Playwriting and Screenwriting",
  "Political Communication",
  "Political Science",
  "Portuguese",
  "Pre-Dentistry",
  "Pre-Law",
  "Pre-Medicine",
  "Pre-Optometry",
  "Pre-Seminary",
  "Pre-Veterinary Medicine",
  "Printmaking",
  "Psychology",
  "Public Administration",
  "Public Health",
  "Public Policy Analysis",
  "Public Relations",
  "Radio and Television",
  "Radiologic Technology",
  "Range Science and Management",
  "Real Estate",
  "Recording Arts Technology",
  "Recreation Management",
  "Rehabilitation Services",
  "Religious Studies",
  "Respiratory Therapy",
  "Risk Management",
  "Rural Sociology",
  "Russian",
  "Scandinavian Studies",
  "Sculpture",
  "Slavic Languages and Literatures",
  "Social Psychology",
  "Social Work",
  "Sociology",
  "Soil Science",
  "Sound Engineering",
  "South Asian Studies",
  "Southeast Asia Studies",
  "Spanish",
  "Special Education",
  "Speech Pathology",
  "Sport and Leisure Studies",
  "Sports Management",
  "Statistics",
  "Surveying",
  "Sustainable Resource Management",
  "Teacher Education",
  "Teaching English as a Second Language",
  "Technical Writing",
  "Technology Education",
  "Textile Engineering",
  "Theatre",
  "Theology",
  "Tourism",
  "Toxicology",
  "Turfgrass Science",
  "Urban Planning",
  "Urban Studies",
  "Visual Communication",
  "Voice",
  "Web Design",
  "Webmaster and Web Management",
  "Welding Engineering",
  "Wildlife Management",
  "Women's Studies",
  "Youth Ministries",
  "Zoology",
];

const Cities = [
  "Aberdeen",
  "Abilene",
  "Akron",
  "Albany",
  "Albuquerque",
  "Alexandria",
  "Allentown",
  "Amarillo",
  "Anaheim",
  "Anchorage",
  "Ann Arbor",
  "Antioch",
  "Apple Valley",
  "Appleton",
  "Arlington",
  "Arvada",
  "Asheville",
  "Athens",
  "Atlanta",
  "Atlantic City",
  "Augusta",
  "Aurora",
  "Austin",
  "Bakersfield",
  "Baltimore",
  "Barnstable",
  "Baton Rouge",
  "Beaumont",
  "Bel Air",
  "Bellevue",
  "Berkeley",
  "Bethlehem",
  "Billings",
  "Birmingham",
  "Bloomington",
  "Boise",
  "Boise City",
  "Bonita Springs",
  "Boston",
  "Boulder",
  "Bradenton",
  "Bremerton",
  "Bridgeport",
  "Brighton",
  "Brownsville",
  "Bryan",
  "Buffalo",
  "Burbank",
  "Burlington",
  "Cambridge",
  "Canton",
  "Cape Coral",
  "Carrollton",
  "Cary",
  "Cathedral City",
  "Cedar Rapids",
  "Champaign",
  "Chandler",
  "Charleston",
  "Charlotte",
  "Chattanooga",
  "Chesapeake",
  "Chicago",
  "Chula Vista",
  "Cincinnati",
  "Clarke County",
  "Clarksville",
  "Clearwater",
  "Cleveland",
  "College Station",
  "Colorado Springs",
  "Columbia",
  "Columbus",
  "Concord",
  "Coral Springs",
  "Corona",
  "Corpus Christi",
  "Costa Mesa",
  "Dallas",
  "Daly City",
  "Danbury",
  "Davenport",
  "Davidson County",
  "Dayton",
  "Daytona Beach",
  "Deltona",
  "Denton",
  "Denver",
  "Des Moines",
  "Detroit",
  "Downey",
  "Duluth",
  "Durham",
  "El Monte",
  "El Paso",
  "Elizabeth",
  "Elk Grove",
  "Elkhart",
  "Erie",
  "Escondido",
  "Eugene",
  "Evansville",
  "Fairfield",
  "Fargo",
  "Fayetteville",
  "Fitchburg",
  "Flint",
  "Fontana",
  "Fort Collins",
  "Fort Lauderdale",
  "Fort Smith",
  "Fort Walton Beach",
  "Fort Wayne",
  "Fort Worth",
  "Frederick",
  "Fremont",
  "Fresno",
  "Fullerton",
  "Gainesville",
  "Garden Grove",
  "Garland",
  "Gastonia",
  "Gilbert",
  "Glendale",
  "Grand Prairie",
  "Grand Rapids",
  "Grayslake",
  "Green Bay",
  "GreenBay",
  "Greensboro",
  "Greenville",
  "Gulfport-Biloxi",
  "Hagerstown",
  "Hampton",
  "Harlingen",
  "Harrisburg",
  "Hartford",
  "Havre de Grace",
  "Hayward",
  "Hemet",
  "Henderson",
  "Hesperia",
  "Hialeah",
  "Hickory",
  "High Point",
  "Hollywood",
  "Honolulu",
  "Houma",
  "Houston",
  "Howell",
  "Huntington",
  "Huntington Beach",
  "Huntsville",
  "Independence",
  "Indianapolis",
  "Inglewood",
  "Irvine",
  "Irving",
  "Jackson",
  "Jacksonville",
  "Jefferson",
  "Jersey City",
  "Johnson City",
  "Joliet",
  "Kailua",
  "Kalamazoo",
  "Kaneohe",
  "Kansas City",
  "Kennewick",
  "Kenosha",
  "Killeen",
  "Kissimmee",
  "Knoxville",
  "Lacey",
  "Lafayette",
  "Lake Charles",
  "Lakeland",
  "Lakewood",
  "Lancaster",
  "Lansing",
  "Laredo",
  "Las Cruces",
  "Las Vegas",
  "Layton",
  "Leominster",
  "Lewisville",
  "Lexington",
  "Lincoln",
  "Little Rock",
  "Long Beach",
  "Lorain",
  "Los Angeles",
  "Louisville",
  "Lowell",
  "Lubbock",
  "Macon",
  "Madison",
  "Manchester",
  "Marina",
  "Marysville",
  "McAllen",
  "McHenry",
  "Medford",
  "Melbourne",
  "Memphis",
  "Merced",
  "Mesa",
  "Mesquite",
  "Miami",
  "Milwaukee",
  "Minneapolis",
  "Miramar",
  "Mission Viejo",
  "Mobile",
  "Modesto",
  "Monroe",
  "Monterey",
  "Montgomery",
  "Moreno Valley",
  "Murfreesboro",
  "Murrieta",
  "Muskegon",
  "Myrtle Beach",
  "Naperville",
  "Naples",
  "Nashua",
  "Nashville",
  "New Bedford",
  "New Haven",
  "New London",
  "New Orleans",
  "New York",
  "New York City",
  "Newark",
  "Newburgh",
  "Newport News",
  "Norfolk",
  "Normal",
  "Norman",
  "North Charleston",
  "North Las Vegas",
  "North Port",
  "Norwalk",
  "Norwich",
  "Oakland",
  "Ocala",
  "Oceanside",
  "Odessa",
  "Ogden",
  "Oklahoma City",
  "Olathe",
  "Olympia",
  "Omaha",
  "Ontario",
  "Orange",
  "Orem",
  "Orlando",
  "Overland Park",
  "Oxnard",
  "Palm Bay",
  "Palm Springs",
  "Palmdale",
  "Panama City",
  "Pasadena",
  "Paterson",
  "Pembroke Pines",
  "Pensacola",
  "Peoria",
  "Philadelphia",
  "Phoenix",
  "Pittsburgh",
  "Plano",
  "Pomona",
  "Pompano Beach",
  "Port Arthur",
  "Port Orange",
  "Port Saint Lucie",
  "Port St. Lucie",
  "Portland",
  "Portsmouth",
  "Poughkeepsie",
  "Providence",
  "Provo",
  "Pueblo",
  "Punta Gorda",
  "Racine",
  "Raleigh",
  "Rancho Cucamonga",
  "Reading",
  "Redding",
  "Reno",
  "Richland",
  "Richmond",
  "Richmond County",
  "Riverside",
  "Roanoke",
  "Rochester",
  "Rockford",
  "Roseville",
  "Round Lake Beach",
  "Sacramento",
  "Saginaw",
  "Saint Louis",
  "Saint Paul",
  "Saint Petersburg",
  "Salem",
  "Salinas",
  "Salt Lake City",
  "San Antonio",
  "San Bernardino",
  "San Buenaventura",
  "San Diego",
  "San Francisco",
  "San Jose",
  "Santa Ana",
  "Santa Barbara",
  "Santa Clara",
  "Santa Clarita",
  "Santa Cruz",
  "Santa Maria",
  "Santa Rosa",
  "Sarasota",
  "Savannah",
  "Scottsdale",
  "Scranton",
  "Seaside",
  "Seattle",
  "Sebastian",
  "Shreveport",
  "Simi Valley",
  "Sioux City",
  "Sioux Falls",
  "South Bend",
  "South Lyon",
  "Spartanburg",
  "Spokane",
  "Springdale",
  "Springfield",
  "St. Louis",
  "St. Paul",
  "St. Petersburg",
  "Stamford",
  "Sterling Heights",
  "Stockton",
  "Sunnyvale",
  "Syracuse",
  "Tacoma",
  "Tallahassee",
  "Tampa",
  "Temecula",
  "Tempe",
  "Thornton",
  "Thousand Oaks",
  "Toledo",
  "Topeka",
  "Torrance",
  "Trenton",
  "Tucson",
  "Tulsa",
  "Tuscaloosa",
  "Tyler",
  "Utica",
  "Vallejo",
  "Vancouver",
  "Vero Beach",
  "Victorville",
  "Virginia Beach",
  "Visalia",
  "Waco",
  "Warren",
  "Washington",
  "Waterbury",
  "Waterloo",
  "West Covina",
  "West Valley City",
  "Westminster",
  "Wichita",
  "Wilmington",
  "Winston",
  "Winter Haven",
  "Worcester",
  "Yakima",
  "Yonkers",
  "York",
  "Youngstown",
];

const Roles = [
  "Account Collector",
  "Account Executive",
  "Account Manager",
  "Account Representative",
  "Accountant",
  "Accounting Analyst",
  "Accounting Director",
  "Accounts Payable/Receivable Clerk",
  "Actor",
  "Administrative Analyst",
  "Administrative Assistant",
  "Administrative Manager",
  "Administrative Specialist",
  "Application Developer",
  "Area Sales Manager",
  "Artificial Intelligence Engineer",
  "Artist",
  "Assistant Engineer",
  "Assistant Professor",
  "Auditing Clerk",
  "Auditor",
  "B2B Sales Specialist",
  "Bellhop",
  "Benefits Manager",
  "Biological Engineer",
  "Biostatistician",
  "Bookkeeper",
  "Branch Manager",
  "Brand Manager",
  "Brand Strategist",
  "Budget Analyst",
  "Business Analyst",
  "Business Manager",
  "Camera Operator",
  "Caregiver",
  "Cashier",
  "Casino Host",
  "CCO—Chief Customer Officer",
  "CDO—Chief Data Officer",
  "CEO—Chief Executive Officer",
  "CFO—Chief Financial Officer",
  "Chemical Engineer",
  "Chief Engineer",
  "Chief Executive Officer",
  "CHRO—Chief Human Resources Officer",
  "CIO—Chief Information Officer",
  "Civil Engineer",
  "Client Service Specialist",
  "Cloud Architect",
  "CMO—Chief Marketing Officer",
  "Columnist",
  "Commercial Loan Officer",
  "Communications Director",
  "Computer Animator",
  "Computer Programmer",
  "Computer Scientist",
  "Concierge",
  "Consultant",
  "Content Creator",
  "Content Marketing Manager",
  "Content Strategist",
  "Continuous Improvement Consultant",
  "Continuous Improvement Lead",
  "Controller",
  "COO—Chief Operating Officer",
  "Copy Editor",
  "Copywriter",
  "CPO—Chief Product Officer",
  "Credit Authorizer",
  "Credit Counselor",
  "Cruise Director",
  "Cruise Ship Attendant",
  "CTO—Chief Technology Officer",
  "Customer Care Associate",
  "Customer Service",
  "Customer Service Manager",
  "Customer Support",
  "Data Analyst",
  "Data Entry",
  "DevOps Engineer",
  "Digital Marketing Manager",
  "Direct Salesperson",
  "Director of Inside Sales",
  "Director of Maintenance",
  "Director of Photography",
  "Doctor",
  "Drafter",
  "eCommerce Marketing Specialist",
  "Economist",
  "Editor/Proofreader",
  "Electrical Engineer",
  "Engineer",
  "Engineering Technician",
  "Entertainment Specialist",
  "Entrepreneur",
  "Event Planner",
  "Events Manager",
  "Executive Assistant",
  "File Clerk",
  "Film Critic",
  "Finance Director",
  "Finance Manager",
  "Financial Analyst",
  "Financial Planner",
  "Financial Services Representative",
  "Flight Attendant",
  "Front Desk Associate",
  "Front Desk Manager",
  "Geological Engineer",
  "Grant Writer",
  "Graphic Designer",
  "Group Sales",
  "Help Desk",
  "Help Desk Worker/Desktop Support",
  "Hotel Front Door Greeter",
  "Hotel Manager",
  "Hotel Receptionist",
  "Housekeeper",
  "Human Resources",
  "Information Security Analyst",
  "Interior Designer",
  "IT Manager",
  "IT Professional",
  "Journalist",
  "Library Assistant",
  "Lodging Manager",
  "Maintenance Engineer",
  "Market Development Manager",
  "Market Researcher",
  "Marketing Communications Manager",
  "Marketing Consultant",
  "Marketing Director",
  "Marketing Manager",
  "Marketing Research Analyst",
  "Marketing Specialist",
  "Mechanical Engineer",
  "Media Buyer",
  "Media Relations Coordinator",
  "Medical Laboratory Tech",
  "Medical Researcher",
  "Meeting Planner",
  "Mentor",
  "Merchandising Associate",
  "Mining Engineer",
  "Motion Picture Director",
  "Music Producer",
  "Musician",
  "Network Administrator",
  "Novelist/Writer",
  "Nuclear Engineer",
  "Nurse",
  "Nurse Practitioner",
  "Office Assistant",
  "Office Clerk",
  "Office Manager",
  "Online ESL Instructor",
  "Operations Analyst",
  "Operations Assistant",
  "Operations Coordinator",
  "Operations Director",
  "Operations Manager",
  "Operations Professional",
  "Outside Sales Manager",
  "Paralegal",
  "Payroll Clerk",
  "Payroll Manager",
  "Personal Assistant",
  "Personal Trainer",
  "Petroleum Engineer",
  "Photographer",
  "Plant Engineer",
  "Playwright",
  "Porter",
  "Preschool Teacher",
  "Product Designer",
  "Product Manager",
  "Production Engineer",
  "Professor",
  "Program Administrator",
  "Program Manager",
  "Proposal Writer",
  "Public Relations",
  "Public Relations Specialist",
  "Quality Control Coordinator",
  "Quality Engineer",
  "Real Estate Broker",
  "Receptionist",
  "Research Assistant",
  "Researcher",
  "Reservationist",
  "Retail Worker",
  "Risk Manager",
  "Safety Engineer",
  "Sales Analyst",
  "Sales Associate",
  "Sales Engineer",
  "Sales Manager",
  "Sales Representative",
  "Screenwriter",
  "Scrum Master",
  "Secretary",
  "SEO Manager",
  "Social Media Assistant",
  "Social Media Specialist",
  "Software Engineer",
  "Sound Engineer",
  "Spa Manager",
  "Speechwriter",
  "SQL Developer",
  "Store Manager",
  "Strategy Consultant",
  "Substitute Teacher",
  "Teacher",
  "Teaching Assistant",
  "Tech Consultant",
  "Technical Specialist",
  "Technical Support Specialist",
  "Technical Writer",
  "Test Scorer",
  "Title Analyst",
  "Title Researcher",
  "Translator",
  "Travel Agent",
  "Travel Nurse",
  "Travel Writer",
  "Tutor/Online Tutor",
  "UX Designer & UI Developer",
  "Valet",
  "Vice President of Marketing",
  "Vice President of Operations",
  "Video Editor",
  "Video Game Writer",
  "Video or Film Producer",
  "Virtual Assistant",
  "Volunteer",
  "Web Designer",
  "Web Developer",
  "Wedding Coordinator",
];

export { Colleges, Majors, Cities, Roles };
